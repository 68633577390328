$spacing: 10px;
$top: 64px;

.navigation {
  padding: $spacing * 2 $spacing $spacing;
  margin: 0 $spacing $spacing $spacing * 2;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);;
  position: sticky;
  top: $top;
  z-index: 1000;
  background: white;

  .up-button {
    margin-right: $spacing * 2;
  }
}