.comparison {
  
  .MuiTypography-root.MuiTypography-body1 {
    padding-top: 50px;
  }

  .MuiTabs-root {
    padding-top: 75px;

    .MuiTab-root {
      padding-left: 25px;
      padding-right: 25px;

      .icon {
        font-size: 2.8em;
      }

      .MuiTab-wrapper {
        text-transform: none;
      } 
    } 
  }
}