.follow-us-list {
  display: flex;
  justify-content: center;

  .MuiListItem-button {
    width: auto;
    padding: 5px;
    border-radius: 50%;
    margin: 0 5px;

    .MuiListItemIcon-root {
      min-width: auto;

      .icon {
        margin: auto;
        height: 30px;
        width: 30px;
      }
    } 
  }
}
