$top: 64px;

.right-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - #{$top});
  position: sticky;
  top: $top;
  box-sizing: border-box;
  padding: 55px 20px 20px 10px;
  font-size: 0.85em;

  .right-panel-top {
    padding-top: 15px;

    .link {
      margin-top: 15px;
      float: right;
    }
  }

  .right-panel-bottom {
    margin-top: auto;
  }
}
