.home {

  .title-section {
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -64px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    .MuiContainer-root {
      margin-bottom: 5%;
    }

    .video-background {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;     
      height: auto;

      @media (min-aspect-ratio: 16/9) {
        & {
            width:100%;
            height: auto;
        }
      }

      @media (max-aspect-ratio: 16/9) {
        & { 
            width:auto;
            height: 100%;
        }
      }

      @media (max-width: 767px) {
        & {
          display: none;
        }
      }
    }

    .logo {
      height: 14em;
      width: auto;
      margin-bottom: 25px;

      @media (max-height: 599px) {
        & {
          max-height: 100px;
          margin-top: 50px;
          margin-bottom: 15px;
        }
      }

      @media (max-width: 599px) {
        & {
          max-height: 200px;
          margin-top: 0;
          margin-bottom: 15px;
        }
      }
    }

    .MuiTypography-h1 {
      text-align: center;
      font-size: 2.8em;
      line-height: 1.6em;
      color: #000000aa;

      @media (max-height: 599px) {
        & {
          font-size: 1.5em;
        }
      }

      @media (max-width: 599px) {
        & {
          font-size: 2.2em;
        }
      }
    }

    .MuiTypography-h6 {
      font-weight: 300;
      font-size: 1.8em;
      color: #757575;
      padding-top: 0;
    }

    @media (max-width: 599px), (max-height: 599px) {
      .MuiTypography-h6 {
        display: none;
      }
    }
  }

  .follow-us-list .MuiListItem-button .MuiListItemIcon-root .icon {
    height: 40px;
    width: 40px;
  }
}
