html, body, #root, .app {
  min-height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
}

.main {
  padding-top: 64px;
  flex-grow: 1;
}

.grecaptcha-badge { 
  visibility: hidden; 
}