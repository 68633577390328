.browser-data-consent.MuiSnackbar-root {
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  z-index: 400;

  .MuiSnackbarContent-root {
    background-color: #306EAB;
    flex-wrap: nowrap;
  }

  .link {
    color: #28B478;
  }
}
