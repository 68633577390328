.drop-area {
  margin-top: 50px;
  height: 100px;
  border: 2px dashed rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);

  .icon {
    width: 2rem;
    height: 2rem;
  }

  .caption {
    margin-top: 0.3rem;
  }
}