.footer {
  margin-top: auto;
  padding: 0 0 75px;
  white-space: nowrap;

  .top-line {
    margin-bottom: 100px;
  }

  .section-container {
    padding: 0 20%;

    @media (max-width: 950px) {
      padding: 0 5%;
    }

    @media (max-width: 650px) {
      padding: 0;
    }

    .section {
      padding: 50px 5%;
      margin-bottom: 0;

      .icon {
        color: #306EAB;
      }

      .MuiList-root {
        padding: 0;
      }

      .MuiListItemText-root {
        margin: 0 0 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        background: linear-gradient(180deg, #306EAB 0%, #28B478 100%);
        padding: 0 1px;

        .contact-us-wrapper {
          background-color: white;
          height: 100%;
          padding: 0 18%;

          .MuiList-root {
            padding: 50px 0;

            .link {
              margin-bottom: 25px;
            }

            .MuiTypography-root {
              padding-top: 25px;
            }
          }
        }
      }

      &:nth-child(3) {
 
        .link:not(:last-child) {
          margin-bottom: 25px;
        }
      }

      @media (max-width: 599px) {
        &:nth-child(1) {
          text-align: right;
          padding-right: 20px;

          .MuiList-root {
            display: inline-flex;
          }
        }

        &:nth-child(2) {
          padding-left: 0;
          margin-left: -1px;

          .contact-us-wrapper {
            display: none;
          }
        }

        &:nth-child(3) {
          padding-left: 25px;
        }
      }
    }
  }

  .link:hover {
    text-decoration: underline;
  }

  .link, .link:hover, .link:active, .link:visited,
  .icon {
    color: inherit;
  }

  .MuiListItem-root {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;

    .MuiListItemText-secondary {
      padding-top: 5px;
    }

    .follow-us-list {
      padding-top: 3px;;

      .MuiListItem-button {
        margin-right: 10px;

        .MuiListItemIcon-root {

          .icon {
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }

  .copyright {
    font-family: 'Roboto';
    padding: 50px 0 0;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.62);
  }
}