.how-it-works {

  .step {
    padding-bottom: 60px;
  }

  .step + .MuiTypography-root.MuiTypography-body1 {
    padding-top: 0;
  }
  
  .scheme {
    margin: 75px 0 0;
  }

  .scheme-mobile {
    margin: 75px 5% 0;
    max-width: 125px;

    &.double-column {
      max-width: 350px;
    }
  }

  @media (max-width: 599px) {
    .scheme {
      display: none;
    }
  }

  @media (min-width: 600px) {
    .scheme-mobile {
      display: none;
    }
  }
}