.app-bar {

  .toolbar {
    background-color: #306EAB;
  }
}

@media (max-width: 599px) {
  .makeStyles-search-4 {
    display: none;
  }
}

.app-bar, .drawer {
  .site-title {
    display: flex;
    align-items: center;
    text-decoration: none;

    .logo {
      margin-right: 14px;
      border-radius: 50%;
      display: inline-block;
      width: 38px;
      height: 38px;
    }

    h1 {
      font-size: 2em;
      font-family: 'Roboto';
      font-weight: 100;
    }
  }
}

.app-bar {
  .site-title {
    color: white;

    .logo {
      background: white;
    }
  }
}

.drawer {
  .link, .link:hover, .link:active, .link:visited {
    color: inherit;
  }

  .icon.fa-w-16 {
    min-width: 24px;
    min-height: 20px;
    box-sizing: border-box;
    padding: 0 2px;
  }

  .MuiDrawer-paper {
    min-width: 270px;
  }

  .MuiListItem-root {
    padding-left: 25px;
    padding-right: 25px;
  }

  .site-title {
    margin: 13px auto 12px;
    color: #306EAB;
  }

  .bottom-container {
    margin-top: auto;

    .follow-us-container {
      padding: 20px;
  
      .follow-us {
        text-align: center;
      }
    }
  }  
}
