@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400|Open+Sans:400,600&display=swap);
.not-found {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  padding-top: 10%; }
  .not-found .icon {
    width: 5rem;
    height: 5rem; }

.app-bar .toolbar {
  background-color: #306EAB; }

@media (max-width: 599px) {
  .makeStyles-search-4 {
    display: none; } }

.app-bar .site-title, .drawer .site-title {
  display: flex;
  align-items: center;
  text-decoration: none; }
  .app-bar .site-title .logo, .drawer .site-title .logo {
    margin-right: 14px;
    border-radius: 50%;
    display: inline-block;
    width: 38px;
    height: 38px; }
  .app-bar .site-title h1, .drawer .site-title h1 {
    font-size: 2em;
    font-family: 'Roboto';
    font-weight: 100; }

.app-bar .site-title {
  color: white; }
  .app-bar .site-title .logo {
    background: white; }

.drawer .link, .drawer .link:hover, .drawer .link:active, .drawer .link:visited {
  color: inherit; }

.drawer .icon.fa-w-16 {
  min-width: 24px;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 2px; }

.drawer .MuiDrawer-paper {
  min-width: 270px; }

.drawer .MuiListItem-root {
  padding-left: 25px;
  padding-right: 25px; }

.drawer .site-title {
  margin: 13px auto 12px;
  color: #306EAB; }

.drawer .bottom-container {
  margin-top: auto; }
  .drawer .bottom-container .follow-us-container {
    padding: 20px; }
    .drawer .bottom-container .follow-us-container .follow-us {
      text-align: center; }

.follow-us-list {
  display: flex;
  justify-content: center; }
  .follow-us-list .MuiListItem-button {
    width: auto;
    padding: 5px;
    border-radius: 50%;
    margin: 0 5px; }
    .follow-us-list .MuiListItem-button .MuiListItemIcon-root {
      min-width: auto; }
      .follow-us-list .MuiListItem-button .MuiListItemIcon-root .icon {
        margin: auto;
        height: 30px;
        width: 30px; }

.chart-container {
  background: white;
  margin-top: 25px; }
  .chart-container .title, .chart-container .subtitle {
    text-align: left;
    font-family: 'Roboto'; }
  .chart-container .title {
    padding-top: 25px;
    color: rgba(0, 0, 0, 0.75); }
  .chart-container .subtitle {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 10px;
    padding-bottom: 25px; }
  .chart-container .legend {
    list-style: none;
    display: flex;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0; }
    .chart-container .legend .legend-item {
      white-space: nowrap;
      margin: 0 25px 0 0; }
      .chart-container .legend .legend-item:last-child {
        margin-right: 0; }
      .chart-container .legend .legend-item .item-color-box {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        float: left; }
      .chart-container .legend .legend-item .caption {
        font-family: 'Roboto';
        margin-left: 10px;
        line-height: 18px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.75);
        float: left; }
  .chart-container .chart {
    margin-top: -30px;
    margin-bottom: 20px;
    width: 100%;
    height: 400px; }

.browser-data-consent.MuiSnackbar-root {
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: none;
          transform: none;
  z-index: 400; }
  .browser-data-consent.MuiSnackbar-root .MuiSnackbarContent-root {
    background-color: #306EAB;
    flex-wrap: nowrap; }
  .browser-data-consent.MuiSnackbar-root .link {
    color: #28B478; }

.footer {
  margin-top: auto;
  padding: 0 0 75px;
  white-space: nowrap; }
  .footer .top-line {
    margin-bottom: 100px; }
  .footer .section-container {
    padding: 0 20%; }
    @media (max-width: 950px) {
      .footer .section-container {
        padding: 0 5%; } }
    @media (max-width: 650px) {
      .footer .section-container {
        padding: 0; } }
    .footer .section-container .section {
      padding: 50px 5%;
      margin-bottom: 0; }
      .footer .section-container .section .icon {
        color: #306EAB; }
      .footer .section-container .section .MuiList-root {
        padding: 0; }
      .footer .section-container .section .MuiListItemText-root {
        margin: 0 0 25px; }
        .footer .section-container .section .MuiListItemText-root:last-child {
          margin-bottom: 0; }
      .footer .section-container .section:nth-child(2) {
        background: -webkit-gradient(linear, left top, left bottom, from(#306EAB), to(#28B478));
        background: linear-gradient(180deg, #306EAB 0%, #28B478 100%);
        padding: 0 1px; }
        .footer .section-container .section:nth-child(2) .contact-us-wrapper {
          background-color: white;
          height: 100%;
          padding: 0 18%; }
          .footer .section-container .section:nth-child(2) .contact-us-wrapper .MuiList-root {
            padding: 50px 0; }
            .footer .section-container .section:nth-child(2) .contact-us-wrapper .MuiList-root .link {
              margin-bottom: 25px; }
            .footer .section-container .section:nth-child(2) .contact-us-wrapper .MuiList-root .MuiTypography-root {
              padding-top: 25px; }
      .footer .section-container .section:nth-child(3) .link:not(:last-child) {
        margin-bottom: 25px; }
      @media (max-width: 599px) {
        .footer .section-container .section:nth-child(1) {
          text-align: right;
          padding-right: 20px; }
          .footer .section-container .section:nth-child(1) .MuiList-root {
            display: inline-flex; }
        .footer .section-container .section:nth-child(2) {
          padding-left: 0;
          margin-left: -1px; }
          .footer .section-container .section:nth-child(2) .contact-us-wrapper {
            display: none; }
        .footer .section-container .section:nth-child(3) {
          padding-left: 25px; } }
  .footer .link:hover {
    text-decoration: underline; }
  .footer .link, .footer .link:hover, .footer .link:active, .footer .link:visited,
  .footer .icon {
    color: inherit; }
  .footer .MuiListItem-root {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0; }
    .footer .MuiListItem-root .MuiListItemText-secondary {
      padding-top: 5px; }
    .footer .MuiListItem-root .follow-us-list {
      padding-top: 3px; }
      .footer .MuiListItem-root .follow-us-list .MuiListItem-button {
        margin-right: 10px; }
        .footer .MuiListItem-root .follow-us-list .MuiListItem-button .MuiListItemIcon-root .icon {
          height: 25px;
          width: 25px; }
  .footer .copyright {
    font-family: 'Roboto';
    padding: 50px 0 0;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.62); }

.gradient-line {
  height: 1px;
  background: -webkit-gradient(linear, left top, right top, from(#FF3939), to(#FFAE39));
  background: linear-gradient(90deg, #FF3939 0%, #FFAE39 100%); }

.blue-green {
  background: -webkit-gradient(linear, left top, right top, from(#306EAB), to(#28B478));
  background: linear-gradient(90deg, #306EAB 0%, #28B478 100%); }

.scroll-down-indicator {
  position: absolute;
  bottom: 100px;
  z-index: 2;
  display: inline-block;
  font: normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  -webkit-transition: opacity .3s;
  transition: opacity .3s; }
  @media (max-width: 599px), (max-height: 599px) {
    .scroll-down-indicator {
      bottom: 30px; } }

.scroll-down-indicator {
  padding-top: 60px; }

.scroll-down-indicator .arrow {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #7f7f7f;
  border-bottom: 1px solid #7f7f7f;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-animation: scroll-down-indicator-animation 2s infinite;
          animation: scroll-down-indicator-animation 2s infinite;
  box-sizing: border-box; }

@-webkit-keyframes scroll-down-indicator-animation {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0); }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
            transform: rotate(-45deg) translate(-10px, 10px); }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0); } }

@keyframes scroll-down-indicator-animation {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0); }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
            transform: rotate(-45deg) translate(-10px, 10px); }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0); } }

.section {
  padding-top: 150px;
  padding-bottom: 150px; }
  .section.with-background {
    background-color: #f5fcff; }
  .section.title-section {
    background: rgba(0, 0, 0, 0.02);
    padding-top: 25px;
    padding-bottom: 25px; }
  .section .MuiTypography-h1 {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 2.5em;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left; }
  .section > .MuiContainer-root > .icon {
    color: #306EAB;
    height: 112px;
    width: auto; }
  .section > .MuiContainer-root > .fa-w-16.icon {
    padding: 10px;
    box-sizing: border-box; }
  .section .MuiTypography-h2 {
    font-family: 'Roboto';
    font-size: 1.8em;
    line-height: 1.4em;
    padding-top: 20px; }
  .section .MuiTypography-h6 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1.4em;
    padding-top: 50px; }
  .section .MuiTypography-root.MuiTypography-body1 {
    font-family: 'Open Sans';
    text-align: justify;
    padding-top: 75px;
    font-size: 1.2em;
    line-height: 1.7em; }
  .section .MuiTypography-root.MuiTypography-body2 {
    font-family: 'Open Sans';
    text-align: justify;
    padding-top: 25px;
    font-size: 1em;
    line-height: 1.7em; }
  .section .MuiFormControlLabel-label {
    padding-bottom: 0; }
  .section .MuiTabs-indicator {
    background-color: #FFAE39; }
  .section .list .list-item {
    padding-left: 10px; }
  .section .MuiFormControlLabel-root .MuiTypography-root.MuiTypography-body1 {
    padding-top: 0; }

.progress-bar .caption {
  font-family: 'Roboto';
  font-weight: 300;
  text-align: center;
  padding-bottom: 12px; }

.progress-bar .MuiLinearProgress-root {
  background-color: rgba(40, 180, 119, 0.45); }

.progress-bar .MuiLinearProgress-barColorPrimary {
  background-color: #28B478; }

.intro .media-file-life-cycle {
  margin: 75px auto 0;
  max-width: 600px; }

.work-results .media-software {
  margin: 75px auto 0;
  max-width: 100%;
  -webkit-filter: drop-shadow(3px 3px 3px #222);
          filter: drop-shadow(3px 3px 3px #222); }

.comparison .MuiTypography-root.MuiTypography-body1 {
  padding-top: 50px; }

.comparison .MuiTabs-root {
  padding-top: 75px; }
  .comparison .MuiTabs-root .MuiTab-root {
    padding-left: 25px;
    padding-right: 25px; }
    .comparison .MuiTabs-root .MuiTab-root .icon {
      font-size: 2.8em; }
    .comparison .MuiTabs-root .MuiTab-root .MuiTab-wrapper {
      text-transform: none; }

.roadmap {
  text-align: left;
  justify-content: center; }
  .roadmap .icon {
    font-size: 6em;
    color: #306EAB; }
  .roadmap .MuiPaper-root {
    background: none; }
  .roadmap .MuiTypography-root.MuiTypography-body1,
  .roadmap .MuiTypography-root.MuiTypography-body2 {
    padding-top: 0; }
  .roadmap .platforms {
    display: flex;
    font-size: 0.25em;
    padding-top: 75px;
    padding-bottom: 20px;
    justify-content: center; }
    .roadmap .platforms .MuiListItem-root {
      width: 40px; }
    .roadmap .platforms .MuiListItemIcon-root {
      min-width: auto; }
  .roadmap .product {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
  .roadmap .MuiStepper-root {
    padding: 0; }
    .roadmap .MuiStepper-root .MuiStepConnector-vertical {
      padding: 0;
      margin-left: 15px; }
    .roadmap .MuiStepper-root .MuiStepLabel-labelContainer {
      margin-left: 20px; }
    .roadmap .MuiStepper-root .MuiStepIcon-root {
      font-size: 2em; }
    .roadmap .MuiStepper-root .MuiStepIcon-active {
      color: #FFAE39; }

.subscribe-and-follow .subscribe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px; }
  .subscribe-and-follow .subscribe-form .subscribe {
    width: 100%;
    display: flex;
    align-items: baseline;
    margin-top: 20px; }
    .subscribe-and-follow .subscribe-form .subscribe .MuiTextField-root {
      flex: 1 1;
      margin-right: 20px; }
    .subscribe-and-follow .subscribe-form .subscribe .MuiButton-root {
      height: 40px; }
      .subscribe-and-follow .subscribe-form .subscribe .MuiButton-root:not(.Mui-disabled) {
        background-color: #28B478;
        color: #fff; }
  .subscribe-and-follow .subscribe-form .MuiFormControlLabel-root {
    font-size: 0.7em;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-right: auto;
    flex: 1 1; }

.subscribe-and-follow .follow-us-list {
  padding: 25px 0 0 0; }
  .subscribe-and-follow .follow-us-list .icon {
    color: #306EAB; }

.pricing .cards-container {
  padding-top: 75px;
  justify-content: center; }

.pricing .pricing-card {
  background: -webkit-gradient(linear, left top, left bottom, from(#306EAB), to(#28B478));
  background: linear-gradient(180deg, #306EAB 0% 80%, #28B478 100%);
  border-radius: 5px;
  padding: 1px;
  box-sizing: border-box;
  height: 100%;
  max-width: 250px;
  margin: 0 auto; }
  .pricing .pricing-card .pricing-card-content-wrapper {
    box-sizing: border-box;
    background-color: white;
    border-radius: 4.7px;
    height: 100%;
    width: 100%;
    padding: 50px 25px 55px; }
  .pricing .pricing-card .size {
    color: #28B478;
    font-family: 'Roboto';
    font-size: 2.2em;
    font-weight: 300; }
  .pricing .pricing-card .MuiSlider-root {
    color: #28B478;
    margin-top: 5px; }
  .pricing .pricing-card .price-value {
    color: #757575;
    font-family: 'Roboto';
    font-size: 2em;
    padding-top: 10px; }
    .pricing .pricing-card .price-value .cost {
      color: #306EAB; }
    .pricing .pricing-card .price-value .period {
      font-size: 0.7em; }
  .pricing .pricing-card .MuiButton-root {
    margin-top: 25px;
    width: 100%; }
  .pricing .pricing-card .gradient-line {
    margin-top: 25px; }
  .pricing .pricing-card .MuiList-root {
    padding: 25px 0 0 0; }
    .pricing .pricing-card .MuiList-root .MuiListItem-root {
      padding: 0 0 25px 0;
      font-family: 'Roboto';
      font-weight: 300;
      font-size: 0.9em; }
      .pricing .pricing-card .MuiList-root .MuiListItem-root:last-child {
        padding-bottom: 0; }

.home .title-section {
  height: 100vh;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -64px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden; }
  .home .title-section .MuiContainer-root {
    margin-bottom: 5%; }
  .home .title-section .video-background {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: auto; }
    @media (min-aspect-ratio: 16 / 9) {
      .home .title-section .video-background {
        width: 100%;
        height: auto; } }
    @media (max-aspect-ratio: 16 / 9) {
      .home .title-section .video-background {
        width: auto;
        height: 100%; } }
    @media (max-width: 767px) {
      .home .title-section .video-background {
        display: none; } }
  .home .title-section .logo {
    height: 14em;
    width: auto;
    margin-bottom: 25px; }
    @media (max-height: 599px) {
      .home .title-section .logo {
        max-height: 100px;
        margin-top: 50px;
        margin-bottom: 15px; } }
    @media (max-width: 599px) {
      .home .title-section .logo {
        max-height: 200px;
        margin-top: 0;
        margin-bottom: 15px; } }
  .home .title-section .MuiTypography-h1 {
    text-align: center;
    font-size: 2.8em;
    line-height: 1.6em;
    color: #000000aa; }
    @media (max-height: 599px) {
      .home .title-section .MuiTypography-h1 {
        font-size: 1.5em; } }
    @media (max-width: 599px) {
      .home .title-section .MuiTypography-h1 {
        font-size: 2.2em; } }
  .home .title-section .MuiTypography-h6 {
    font-weight: 300;
    font-size: 1.8em;
    color: #757575;
    padding-top: 0; }
  @media (max-width: 599px), (max-height: 599px) {
    .home .title-section .MuiTypography-h6 {
      display: none; } }

.home .follow-us-list .MuiListItem-button .MuiListItemIcon-root .icon {
  height: 40px;
  width: 40px; }

.file-explorer {
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 10px; }
  .file-explorer .directory-view {
    width: 100%;
    height: 100%;
    position: relative; }
    .file-explorer .directory-view .files-container {
      height: 100%; }
    .file-explorer .directory-view .select-box {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background: #0C5AA61c;
      border: 1px solid #0C5AA650; }
      .file-explorer .directory-view .select-box.visible {
        display: block; }
  .file-explorer .image-gallery {
    max-width: 800px;
    margin: auto; }
  .file-explorer .image-gallery-image, .file-explorer .image-gallery-thumbnail-inner {
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 */
    position: relative; }
    .file-explorer .image-gallery-image img, .file-explorer .image-gallery-thumbnail-inner img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      max-height: 100%; }
  .file-explorer .image-gallery-right-nav::before,
  .file-explorer .image-gallery-left-nav::before {
    color: #e0e0e0;
    text-shadow: none; }
  .file-explorer .dnd-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 6rem;
    height: 5rem;
    opacity: 0.85;
    box-shadow: 8px 8px 5px -7px rgba(0, 0, 0, 0.75);
    background: #e7f9ff;
    z-index: 1000; }
    .file-explorer .dnd-container.visible {
      display: block; }

.file-explorer .file-explorer-items-container {
  position: relative; }

.file-explorer .file-explorer-item {
  position: absolute; }
  .file-explorer .file-explorer-item.placeholder {
    background: rgba(0, 0, 0, 0.08); }

.file-explorer-item {
  height: 6rem;
  width: 6rem; }
  .file-explorer-item > span {
    height: 100%;
    position: absolute;
    flex-direction: column; }
  .file-explorer-item .icon {
    flex: 1 1;
    width: 100%;
    color: #6eaace; }
  .file-explorer-item .picture {
    max-width: 5rem;
    max-height: 3.5rem;
    margin-top: auto; }
  .file-explorer-item .caption {
    text-transform: none;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 0.85rem;
    height: 1.74rem;
    padding: 0.02rem;
    margin-top: auto; }
  .file-explorer-item .checkbox {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    display: none; }
  .file-explorer-item .storage {
    position: absolute;
    left: 6px;
    top: 2px;
    padding: 2px;
    color: #013b6c;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #e6e5e5;
    opacity: 0.75; }
  .file-explorer-item.selected {
    background: rgba(0, 0, 0, 0.08); }
  .file-explorer-item.selected .checkbox, .file-explorer-item:hover .checkbox {
    display: block; }
  .file-explorer-item.drag-over {
    background: #e7f9ff; }

.hide-checkbox .file-explorer-item .checkbox {
  display: none; }

.right-panel .file-explorer-item {
  height: 10rem;
  width: 10rem; }
  .right-panel .file-explorer-item .picture {
    max-width: 9rem;
    max-height: 7rem; }
  .right-panel .file-explorer-item .caption {
    font-size: 1rem;
    line-height: 1.5rem;
    height: auto;
    margin-bottom: 0.5rem;
    font-weight: 400; }
  .right-panel .file-explorer-item .checkbox {
    display: none; }
  .right-panel .file-explorer-item .storage {
    width: 35px;
    height: 35px; }
  .right-panel .file-explorer-item.selected, .right-panel .file-explorer-item:hover {
    background: none; }

.dnd-container .file-explorer-item {
  width: 100%;
  height: 100%; }
  .dnd-container .file-explorer-item .picture {
    margin-top: 0; }
  .dnd-container .file-explorer-item .checkbox, .dnd-container .file-explorer-item .caption {
    display: none; }
  .dnd-container .file-explorer-item, .dnd-container .file-explorer-item.selected, .dnd-container .file-explorer-item:hover {
    background: none; }

.drop-area {
  margin-top: 50px;
  height: 100px;
  border: 2px dashed rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5); }
  .drop-area .icon {
    width: 2rem;
    height: 2rem; }
  .drop-area .caption {
    margin-top: 0.3rem; }

.upload-progress .upload-progress-section {
  padding-top: 30px; }
  .upload-progress .upload-progress-section .details {
    font-family: 'Roboto';
    font-weight: 300;
    text-align: right;
    padding-top: 12px; }

.right-panel {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: calc(100vh - 64px);
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  box-sizing: border-box;
  padding: 55px 20px 20px 10px;
  font-size: 0.85em; }
  .right-panel .right-panel-top {
    padding-top: 15px; }
    .right-panel .right-panel-top .link {
      margin-top: 15px;
      float: right; }
  .right-panel .right-panel-bottom {
    margin-top: auto; }

.resource-allocation .allocation-title, .resource-allocation .allocation-details {
  font-family: 'Roboto';
  font-weight: 300;
  text-align: center; }

.resource-allocation .allocation-title {
  font-size: 1.1em; }

.resource-allocation .allocation-details {
  font-size: 0.9em; }

.resource-allocation .chart-container {
  margin-top: 0; }
  .resource-allocation .chart-container .chart {
    height: 100px;
    width: 100px;
    margin: 0 auto; }

.navigation {
  padding: 20px 10px 10px;
  margin: 0 10px 10px 20px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 1000;
  background: white; }
  .navigation .up-button {
    margin-right: 20px; }

.file-explorer-container {
  display: flex;
  flex-direction: column;
  flex: 1 1; }

.right-panel-container {
  min-width: 330px;
  display: flex;
  justify-content: center; }
  @media (max-width: 599px) {
    .right-panel-container {
      display: none; } }

.how-it-works .step {
  padding-bottom: 60px; }

.how-it-works .step + .MuiTypography-root.MuiTypography-body1 {
  padding-top: 0; }

.how-it-works .scheme {
  margin: 75px 0 0; }

.how-it-works .scheme-mobile {
  margin: 75px 5% 0;
  max-width: 125px; }
  .how-it-works .scheme-mobile.double-column {
    max-width: 350px; }

@media (max-width: 599px) {
  .how-it-works .scheme {
    display: none; } }

@media (min-width: 600px) {
  .how-it-works .scheme-mobile {
    display: none; } }

.registration .MuiGrid-container {
  margin-bottom: 20px; }

.registration .facebook-registration-form {
  padding-top: 75px; }
  .registration .facebook-registration-form .MuiTypography-body1 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .registration .facebook-registration-form .fb-login-button {
    margin-top: 5px; }

.registration .MuiGrid-item:last-child {
  text-align: left; }

.faq .MuiTypography-root.MuiTypography-body2 {
  color: #757575; }

.faq .faq-list {
  padding: 0;
  text-align: justify;
  margin-left: 25px; }
  .faq .faq-list .faq-list-item {
    padding: 0 0 0 25px; }
  .faq .faq-list .inner-list {
    padding: 0;
    list-style-type: none; }
    .faq .faq-list .inner-list .inner-list-item {
      display: flex;
      padding-top: 25px; }
    .faq .faq-list .inner-list .icon {
      color: #757575;
      font-size: 1em;
      margin-bottom: -4px;
      margin-right: 25px; }
    .faq .faq-list .inner-list .MuiTypography-body2 {
      padding-top: 0; }

.last-updated {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 0; }

html, body, #root, .app {
  min-height: 100vh; }

.app {
  display: flex;
  flex-direction: column; }

.main {
  padding-top: 64px;
  flex-grow: 1; }

.grecaptcha-badge {
  visibility: hidden; }

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

b {
  font-weight: 600; }

.app-bar .toolbar {
  min-height: 64px; }

.link, .link:hover, .link:active, .link:visited {
  color: #306EAB;
  font-weight: 600;
  text-decoration: none; }

.link:hover {
  text-decoration: underline; }

.blog-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  text-align: center;
  width: 100%; }

.hidden {
  display: none; }

.embedded-video-container {
  margin: 75px 10% 0;
  width: 80%; }
  .embedded-video-container .embedded-video-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; }
    .embedded-video-container .embedded-video-wrapper .embedded-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }

