.roadmap {
  text-align: left;
  justify-content: center;

  .icon {
    font-size: 6em;
    color: #306EAB;
  }

  .MuiPaper-root {
    background: none;
  }

  .MuiTypography-root.MuiTypography-body1,
  .MuiTypography-root.MuiTypography-body2 {
    padding-top: 0;
  }

  .platforms {
    display: flex;
    font-size: 0.25em;
    padding-top: 75px;
    padding-bottom: 20px;
    justify-content: center;

    .MuiListItem-root {
      width: 40px;
    }

    .MuiListItemIcon-root {
      min-width: auto;
    }
  }

  .product {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .MuiStepper-root {
    padding: 0;

    .MuiStepConnector-vertical {
      padding: 0;
      margin-left: 15px;
    }

    .MuiStepLabel-labelContainer {
      margin-left: 20px;
    }
  
    .MuiStepIcon-root {
      font-size: 2em;
    }

    .MuiStepIcon-active {
      color: #FFAE39;
    }
  }
}
