.file-explorer {
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 10px;

  .directory-view {
    width: 100%;
    height: 100%;
    position: relative;

    .files-container {
      height: 100%;
    }

    .select-box {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background: #0C5AA61c;
      border: 1px solid #0C5AA650;

      &.visible {
        display: block;
      }
    }
  }

  .image-gallery {
    max-width: 800px;
    margin: auto;
  }

  .image-gallery-image, .image-gallery-thumbnail-inner {
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;
    
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      max-height: 100%;
    }
  }

  .image-gallery-right-nav::before,
  .image-gallery-left-nav::before {
    color: #e0e0e0;
    text-shadow: none;
  }

  .dnd-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 6rem;
    height: 5rem;
    opacity: 0.85;
    box-shadow: 8px 8px 5px -7px rgba(0, 0, 0, 0.75);
    background: #e7f9ff;
    z-index: 1000;

    &.visible {
      display: block;
    }
  }
}
