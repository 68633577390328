.not-found {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;

  .icon {
    width: 5rem;
    height: 5rem;
  }
}