.file-explorer-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.right-panel-container {
  min-width: 330px;
  display: flex;
  justify-content: center;

  @media (max-width: 599px) {
    & {
      display: none;
    }
  }
}
