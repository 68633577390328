.faq {

  .MuiTypography-root.MuiTypography-body2 {
    color: #757575;
  }
  
  .faq-list {
    padding: 0;
    text-align: justify;
    margin-left: 25px;

    .faq-list-item {
      padding: 0 0 0 25px;
    }

    .inner-list {
      padding: 0;
      list-style-type: none;

      .inner-list-item {
        display: flex;
        padding-top: 25px;
      }

      .icon {
        color: #757575;
        font-size: 1em;
        margin-bottom: -4px;
        margin-right: 25px;
      }

      .MuiTypography-body2 {
        padding-top: 0;
      }
    }
  }
}