.file-explorer {
  .file-explorer-items-container {
    position: relative;
  }

  .file-explorer-item {
    position: absolute;

    &.placeholder {
      background: rgba(0, 0, 0, 0.08);
    }
  }  
}

.file-explorer-item {
  height: 6rem;
  width: 6rem;

  & > span {
    height: 100%;
    position: absolute;
    flex-direction: column;
  }

  .icon {
    flex: 1;
    width: 100%;
    color: #6eaace;
  }

  .picture {
    max-width: 5rem;
    max-height: 3.5rem;
    margin-top: auto;
  }

  .caption {
    text-transform: none;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 0.85rem;
    height: 1.74rem;
    padding: 0.02rem;
    margin-top: auto;
  }

  .checkbox {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    display: none;
  }

  .storage {
    position: absolute;
    left: 6px;
    top: 2px;
    padding: 2px;
    color: #013b6c;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: rgb(230, 229, 229);
    opacity: 0.75;
  }

  &.selected {
    background: rgba(0, 0, 0, 0.08);
  }

  &.selected, &:hover {
    .checkbox {
      display: block;
    }
  }

  &.drag-over {
    background: #e7f9ff;
  }
}

.hide-checkbox .file-explorer-item {
  .checkbox {
    display: none;
  }
}

.right-panel {
  .file-explorer-item {
    height: 10rem;
    width: 10rem;

    .picture {
      max-width: 9rem;
      max-height: 7rem;
    }

    .caption {
      font-size: 1rem;
      line-height: 1.5rem;
      height: auto;
      margin-bottom: 0.5rem;
      font-weight: 400;
    }

    .checkbox {
      display: none;
    }

    .storage {
      width: 35px;
      height: 35px;
    }

    &.selected, &:hover {
      background: none;
    }
  } 
}

.dnd-container {
  .file-explorer-item {
    width: 100%;
    height: 100%;

    .picture {
      margin-top: 0;
    }

    .checkbox, .caption {
      display: none;
    }

    &, &.selected, &:hover {
      background: none;
    }
  }
}
