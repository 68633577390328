.chart-container {
  background: white;
  margin-top: 25px;

  .title, .subtitle {
    text-align: left;
    font-family: 'Roboto';
  }

  .title {
    padding-top: 25px;
    color: rgba($color: #000000, $alpha: 0.75);

  }

  .subtitle {
    font-size: 0.8em;
    color: rgba($color: #000000, $alpha: 0.5);
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .legend {
    list-style: none;
    display: flex;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0;

    .legend-item {
      white-space: nowrap;
      margin: 0 25px 0 0;

      &:last-child {
        margin-right: 0;
      }

      .item-color-box {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        float: left;
      }

      .caption {
        font-family: 'Roboto';
        margin-left: 10px;
        line-height: 18px;
        font-size: 14px;
        color: rgba($color: #000000, $alpha: 0.75);
        float: left;
      }
    }
  }

  .chart {
    margin-top: -30px;
    margin-bottom: 20px;
    width: 100%;
    height: 400px;
  }
}
