@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400|Open+Sans:400,600&display=swap');
@import '../node_modules/animate.css/animate.min.css';

$color1: #306EAB;
$color2: #FF3939;
$color3: #28B478;
$color4: #FFAE39;
$color5: #757575;
$color4: #152d46;
$color5: #f5fcff;

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  font-weight: 600;
}

.app-bar .toolbar {
  min-height: 64px;
}

.link, .link:hover, .link:active, .link:visited {
  color: #306EAB;
  font-weight: 600;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.blog-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  text-align: center;
  width: 100%;
}

.hidden {
  display: none;
}

.embedded-video-container {
  margin: 75px 10% 0;
  width: 80%;

  .embedded-video-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  
    .embedded-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
