.upload-progress {

  .upload-progress-section {
    padding-top: 30px;

    .details {
      font-family: 'Roboto';
      font-weight: 300;
      text-align: right;
      padding-top: 12px;
    }
  }
}