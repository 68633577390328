.registration {

  .MuiGrid-container {
    margin-bottom: 20px;
  }

  .facebook-registration-form {
    padding-top: 75px;

    .MuiTypography-body1 {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .fb-login-button {
      margin-top: 5px;
    }
  }

  .MuiGrid-item:last-child {
    text-align: left;
  }
}