.section {
  padding-top: 150px;
  padding-bottom: 150px;

  &.with-background {
    background-color: #f5fcff;
  }

  &.title-section {
    background: rgba(0, 0, 0, 0.02);
    padding-top: 25px;
    padding-bottom: 25px;  
  }

  .MuiTypography-h1 {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 2.5em;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left;
  }

  & > .MuiContainer-root > .icon {
    color: #306EAB;
    height: 112px;
    width: auto;
  }

  & > .MuiContainer-root > .fa-w-16.icon {
    padding: 10px;
    box-sizing: border-box;
  }

  .MuiTypography-h2 {
    font-family: 'Roboto';
    font-size: 1.8em;
    line-height: 1.4em;
    padding-top: 20px;
  }
  
  .MuiTypography-h6 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1.4em;
    padding-top: 50px;
  }
  
  .MuiTypography-root.MuiTypography-body1 {
    font-family: 'Open Sans';
    text-align: justify;
    padding-top: 75px;
    font-size: 1.2em;
    line-height: 1.7em;
  }

  .MuiTypography-root.MuiTypography-body2 {
    font-family: 'Open Sans';
    text-align: justify;
    padding-top: 25px;
    font-size: 1em;
    line-height: 1.7em;
  }

  .MuiFormControlLabel-label {
    padding-bottom: 0;
  }

  .MuiTabs-indicator {
    background-color: #FFAE39;
  }

  .list {

    .list-item {
      padding-left: 10px;
    }
  }

  .MuiFormControlLabel-root {

    .MuiTypography-root.MuiTypography-body1 {
      padding-top: 0;
    }
  }
}
