.pricing {

  .cards-container {
    padding-top: 75px;
    justify-content: center;
  }

  .pricing-card {
    background: linear-gradient(180deg, #306EAB 0% 80%, #28B478 100%);
    border-radius: 5px;
    padding: 1px;
    box-sizing: border-box;
    height: 100%;
    max-width: 250px;
    margin: 0 auto;

    .pricing-card-content-wrapper {
      box-sizing: border-box;
      background-color: white;
      border-radius: 4.7px;
      height: 100%;
      width: 100%;
      padding: 50px 25px 55px;
    }

    .size {
      color: #28B478;
      font-family: 'Roboto';
      font-size: 2.2em;
      font-weight: 300;
    }

    .MuiSlider-root {
      color: #28B478;
      margin-top: 5px;
    }

    .price-value {
      color: #757575;
      font-family: 'Roboto';
      font-size: 2em;
      padding-top: 10px;

      .cost {
        color: #306EAB;
      }

      .period {
        font-size: 0.7em;
      }
    }

    .MuiButton-root {
      margin-top: 25px;
      width: 100%;
    }

    .gradient-line {
      margin-top: 25px;
    }

    .MuiList-root {
      padding: 25px 0 0 0;

      .MuiListItem-root {
        padding: 0 0 25px 0;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 0.9em;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}