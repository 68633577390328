.resource-allocation {

  .allocation-title, .allocation-details {
    font-family: 'Roboto';
    font-weight: 300;
    text-align: center;
  }

  .allocation-title {
    font-size: 1.1em;
  }

  .allocation-details {
    font-size: 0.9em;
  }

  .chart-container {
    margin-top: 0;

    .chart {
      height: 100px;
      width: 100px;
      margin: 0 auto;
    }  
  }
}