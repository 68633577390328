.progress-bar {

  .caption {
    font-family: 'Roboto';
    font-weight: 300;
    text-align: center;
    padding-bottom: 12px;
  }

  .MuiLinearProgress-root {
    background-color: rgba(40, 180, 119, 0.45);
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #28B478;
  }
}