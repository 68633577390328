.scroll-down-indicator {
  position: absolute;
  bottom: 100px;
  z-index: 2;
  display: inline-block;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;

  @media (max-width: 599px), (max-height: 599px) {
    & {
      bottom: 30px;
    }
  }
}

.scroll-down-indicator {
  padding-top: 60px;
}

.scroll-down-indicator .arrow {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #7f7f7f;
  border-bottom: 1px solid #7f7f7f;
  transform: rotate(-45deg);
  animation: scroll-down-indicator-animation 2s infinite;
  box-sizing: border-box;
}

@keyframes scroll-down-indicator-animation {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}
