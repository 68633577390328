.subscribe-and-follow {

  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  
    .subscribe {
      width: 100%;
      display: flex;
      align-items: baseline;
      margin-top: 20px;
  
      .MuiTextField-root {
        flex: 1;
        margin-right: 20px;
      }
  
      .MuiButton-root {
        height: 40px;
  
        &:not(.Mui-disabled) {
          background-color: #28B478;
          color: #fff;
        }
      }
    }
  
    .MuiFormControlLabel-root {
      font-size: 0.7em;
      margin-top: 20px;
      margin-bottom: 40px;
      margin-right: auto;
      flex: 1;
    }
  }

  .follow-us-list {
    padding: 25px 0 0 0;

    .icon {
      color: #306EAB;
    }
  }
}